<template>
    <div class="flex-row align-items-center">
      <div class="container">
        <b-row>
          <b-col md="12" sm="6">
            <b-form v-on:keydown.enter="register">
              <div class="display-4 text-center bg-info">{{$t('register.title')}}</div><br/>
              <b class="text-muted h4"># {{$t('register.user_info')}}</b><hr>
              <div class="row">
                <div class="col-md-6">
                  <b-form-group>
                    <label for="txt_name">{{$t('register.input.name')}}</label>
                    <div v-if="$v.user.name.$error" class="pull-right">
                      <label class="text-danger" v-if="!$v.user.name.required">{{$t('register.input.name_required')}}</label>
                    </div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" class="form-control"
                      :placeholder="$t('register.input.placeholder_name')" maxLength="50" v-on:input="$v.user.name.$touch"
                      v-model.trim="user.name"/>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <label for="ddlGender">{{$t('register.select.gender')}}</label>
                    <div v-if="$v.user.gender.$error" class="pull-right">
                        <label class="text-danger" v-if="!$v.user.gender.required">{{$t('register.input.gender_required')}}</label>
                    </div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-select id="ddlGender" class="form-control" v-model.trim="user.gender"
                        v-on:input="$v.user.gender.$touch">
                          <option value="null" disabled>{{$t('register.input.placeholder_gender')}}</option>
                          <option v-for="d in ls_gender" :value="d.id"
                              v-bind:key="d.id">{{d.name[$i18n.locale]}}</option>
                      </b-select>
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
  
              <div class="row">
                <div class="col-md-6">
                  <b-form-group>
                    <label for="ddlPhone">{{$t('register.input.phone')}}</label>
                    <div v-if="$v.user.phone.$error" class="pull-right">
                      <label class="text-danger" v-if="!$v.user.phone.required">{{$t('register.input.phone_required')}}</label>
                      <label class="text-danger" v-else-if="!$v.user.phone.numeric">{{$t('register.input.phone_number_only')}}</label>
                      <label class="text-danger" v-else-if="!$v.user.phone.minLength">{{$t('register.input.phone_ten_number')}}</label>
                      <label class="text-danger" v-else-if="!$v.user.phone.isUnique">{{$t('register.input.phone_existing')}}</label>
                    </div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-input-group-text>@</b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" class="form-control" :placeholder="$t('register.input.placeholder_phone')"
                      maxLength="15" minLength="10"
                      v-model.trim="user.phone" v-on:input="$v.user.phone.$touch"/>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <label for="ddlEmail">{{$t('register.input.email')}}</label>
                    <div v-if="$v.user.email.$error" class="pull-right">
                        <label class="text-danger" v-if="!$v.user.email.required">{{$t('register.input.email_required')}}</label>
                        <label class="text-danger" v-else-if="!$v.user.email.email">{{$t('register.input.email_invalid')}}</label>
                        <label class="text-danger" v-else-if="!$v.user.email.isUnique">{{$t('register.input.email_existing')}}</label>
                    </div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-input-group-text>@</b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="email" class="form-control" :placeholder="$t('register.input.placeholder_email')" autocomplete="email"
                      maxLength="50" v-model.trim="user.email" v-on:input="$v.user.email.$touch"/>
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
  
              <div class="row">
                <div class="col-md-6">
                  <b-form-group>
                    <label for="ddlPass">{{$t('register.input.password')}}</label>
                    <div v-if="$v.user.password.$error" class="pull-right">
                        <label class="text-danger" v-if="!$v.user.password.required">{{$t('register.input.password_required')}}</label>
                    </div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="password" class="form-control" :placeholder="$t('register.input.placeholder_password')"
                      autocomplete="new-password" v-model.trim="user.password" maxLength="20"/>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <label for="ddlRepass">{{$t('register.input.confirm_password')}}</label>
                    <div v-if="$v.user.confirm_password.$error" class="pull-right">
                        <label class="text-danger" v-if="!$v.user.confirm_password.required">{{$t('register.input.confirm_password_required')}}</label>
                        <label class="text-danger" v-else-if="!$v.user.confirm_password.sameAsPassword">{{$t('register.input.confirm_password_invalid')}}</label>
                    </div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="password" class="form-control" :placeholder="$t('register.input.placeholder_confirm_password')"
                      autocomplete="new-password" v-model.trim="user.confirm_password" maxLength="20"/>
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
  
              <div class="row">
                <div class="col">
                  <b-form-group>
                    <label for="ddlAddress">{{$t('register.input.address')}}</label>
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-textarea type="text" class="form-control"
                      :placeholder="this.$t('common.placeholder_address')" maxLength="200"
                      v-model.trim="user.address"/>
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
  
              <b class="text-muted h4"># {{$t('register.company_info')}}</b> <hr>
              <div class="row">
                <div class="col-md-6">
                  <b-form-group>
                    <label for="ddlTenCongy">{{$t('register.input.company_name')}}</label>
                    <div v-if="$v.company.name.$error" class="pull-right">
                        <label class="text-danger" v-if="!$v.company.name.required">{{$t('register.input.company_name_required')}}</label>
                    </div>
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" class="form-control"
                      :placeholder="$t('register.input.placeholder_company_name')" maxLength="50"
                      v-model.trim="company.name" v-on:input="$v.company.name.$touch"/>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <label for="chkcocon">{{$t('register.check.name')}}</label>
                    <b-form-checkbox switch size="lg" id="chkcocon"
                    v-model.trim="company.cocon"></b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
  
              <div class="row">
                <div class="col-md-6">
                  <b-form-group>
                    <label for="ddlSoDienThoai">{{$t('register.input.company_phone')}}</label>
                    <div v-if="$v.company.phone.$error" class="pull-right">
                      <label class="text-danger" v-if="!$v.company.phone.required">{{$t('register.input.company_phone_required')}}</label>
                      <label class="text-danger" v-else-if="!$v.company.phone.numeric">{{$t('register.input.phone_number_only')}}</label>
                      <label class="text-danger" v-else-if="!$v.company.phone.minLength">{{$t('register.input.phone_ten_number')}}</label>
                    </div>
                    <b-input-group class="mb-3">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="text" class="form-control"
                      :placeholder="$t('register.input.placeholder_company_phone')" maxLength="15"
                      v-model.trim="company.phone" v-on:input="$v.company.phone.$touch"/>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <label for="ddlBusiness">{{$t('register.select.business')}}</label>
                    <div v-if="$v.company.business.$error" class="pull-right">
                        <label class="text-danger" v-if="!$v.company.business.required">{{$t('register.input.company_business_required')}}</label>
                    </div>
                    <b-input-group class="mb-3">
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-select id="ddlBusiness" class="form-control" v-model.trim="company.business"
                        v-on:input="$v.company.business.$touch" @change="onBusinessChange(company.business)">
                          <option value="null" disabled>{{$t('register.input.placeholder_business')}}</option>
                          <option v-for="d in ls_business" :value="d.code"
                              v-bind:key="d._id">{{d.name[$i18n.locale]}}</option>
                      </b-select>
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
  
              <div class="row">
                <div class="col">
                  <b-form-group>
                    <label for="ddlCompanyAddress">{{$t('register.input.company_address')}}</label>
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-input-group-text><i class="icon-user"></i></b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-textarea type="text" class="form-control"
                      :placeholder="$t('register.input.placeholder_company_address')" maxLength="200"
                      v-model.trim="company.address"/>
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
            </b-form>
            <b-row class="mb-1">
              <b-col cols="6">
                <a block class="btn btn-info" href="/login" style="text-transform:capitalize;">
                <i class="fa fa-sign-in" aria-hidden="true"></i> <span>{{$t('register.button.login')}}</span></a>
              </b-col>
              <b-col cols="6">
                <a class="btn btn-info pull-right" style="text-transform:capitalize;"
                href="#" @click="register" block><i class="fa fa-check"></i> <span>{{$t('register.button.submit')}}</span></a>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
  </template>
  
  <script>
  import { required, minLength,maxLength,email,numeric,sameAs } from 'vuelidate/lib/validators'
  import Loading from 'vue-loading-overlay'
  import moment from 'moment'
  import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
  import { Role } from '../../helpers/role'
  
  export default {
    name: 'Register',
    components: {Loading},
    data () {
      return {
        ls_business: [],
        ls_gender: [],
        user:{
          id:null,
          name:null,
          email: null,
          phone:null,
          code:moment().valueOf().toString(),
          address:null,
          gender:null,
          password: null,
          confirm_password:null,
          role:Role.Admin,
          branch_id:null
        },
        company:{
          id:null,
          name:null,
          bill_name:"Phiếu Tạm Tính",
          code:moment().valueOf().toString(),
          default_password:'hansan',
          cocon:false,
          discount_all:false,
          address:null,
          phone:null,
          package_id:1,
          is_admin:false,
          business:null,
          logo_url:null,
          discount:0,
          discount_reason:null,
          sang_tu:'09:00',
          sang_den:'12:00',
          chieu_tu:'13:00',
          chieu_den:'18:00'
        },
      }
    },
    validations: {
      user: {
        name: {
          required,
          maxLength:maxLength(50)
        },
        gender: {
          required
        },
        phone: {
          required,
          numeric,
          minLength: minLength(10),
          async isUnique(value) {
            if (value==null) return true
            const res = await this.checkPhoneUnique(value)
            return Boolean(res.data)
          }
        },
        email: {
          required,
          email,
          maxLength:maxLength(50),
          async isUnique(value) {
            if (value==null) return true
            if(this.user && this.user._id!=null) return true
  
            const res = await this.checkEmailUnique(value)
            return Boolean(res.data)
          }
        },
        password: {
          required,
          maxLength:maxLength(20)
        },
        confirm_password:{
          required,
          maxLength:maxLength(20),
          sameAsPassword: sameAs('password')
        }
      },
      company:{
        name: {
          required,
          maxLength:maxLength(50)
        },
        phone: {
          required,
          numeric,
          minLength: minLength(10)
        },
        business:{
          required
        }
      }
    },
    mounted () {
      debugger
      if(this.$route.query.code) this.company.business=this.$route.query.code
      this.ls_business=this.option_business
      this.ls_gender=this.option_gender
    },
    methods: {
      onBusinessChange(code){
        if(code==null) return
        debugger
        let business=this.ls_business.find(x => x.code === code)
        if(business) this.company.logo_url=business.url
      },
      async checkPhoneUnique(phone){
        return await this.$store.dispatch(`st_user/register_phone_unique`,phone)
      },
      async checkEmailUnique(email){
        return await this.$store.dispatch(`st_user/email_unique`,email)
      },
      async sendEmail(param){
        return await this.$store.dispatch(`st_user/register_ok_and_send_email`,param)
      },
      async register () {
        const isValid = await this.isValid()
        if (!isValid) {
          return
        }
        var app = this
  
        var loader = app.$loading.show()
        debugger
        app.$store.dispatch('st_authentication/dang_ky',{user:app.user,company:app.company})
        .then(function (resp) {
          app.$router.push({ path: 'dang-ky-ok', query: { email: app.user.email,name:app.user.name }})
          app.clear()
          debugger
          loader.hide()
        })
        .catch(function (resp) {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.clear()
          loader.hide()
        })
      },
      _validations () {
        return new Promise(resolve => {
          if (this.$v.$error || !this.$v.$pending) {
              return resolve()
          }
          let poll = setInterval(() => {
          if (!this.$v.$pending) {
              clearInterval(poll)
              resolve()
          }
          }, 200)
        })
      },
  
      async isValid () {
        this.$v.$reset()
        this.$v.$touch()
        await this._validations()
        return Promise.resolve(!this.$v.$error)
      },
      clear(){
        this.user={id:null,gender:null,role:Role.Admin,branch_id:null,name:null,email:null,phone:null,code:moment().valueOf().toString(),password:null,confirm_password:null,role:Role.Admin}
        this.company={id:null,name:null,code:moment().valueOf().toString(),cocon:false,address:null,bill_name:"Tạm Tính",
        sang_tu:'09:00',discount:0,discount_reason:null,
        sang_den:'12:00',
        discount_all:false,
        chieu_tu:'13:00',
        chieu_den:'18:00',
        phone:null,package_id:1,is_admin:false,business:null,default_password:'hansan'}
        this.$v.$reset()
      }
    }
  }
  </script>
  